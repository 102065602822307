var _self;
const _module='CTResetPass';
const _common="ctError";
const checkAuthCodeRule=(rule,value,callback)=>{
    _self.checkAuthCode(rule,value,callback);
}
const checkNewPassRule=(rule,value,callback)=>{
    _self.checkPassword(rule,value,callback);
}
const checkConfirmPassRule=(rule,value,callback)=>{
    _self.checkConfirmPassword(rule,value,callback);
}
export default {
    name: 'login',
    components: {
    },
    data() {
        return {
            // step 1
            step1Title: "Enter authentication code",
            phoneLabel: "Mobile:",
            phoneNotifyContent: 'A comfirm message has been send to your phone',
            clickinfo:"Please enter the verify code",
            authcodePlaceholder:"Please enter the verify code",
            step1ButtonText:"Submit",
            // step 2
            step2Title:"Reset your password",
            newpassLabel:"New Password:",
            confirmpassLabel:"Confirm Password:",
            newpassPlaceholder:"New Password",
            confirmpassPlaceholder:"Confirm Password",
            step2ButtonText:"Update Password",
            verificationCode:'Verification code',
            step2notice:"Security is critical at CIC. To keep your account safe, we will text you a verification code to confirm the password reset.",
            // step 3
            successinfo:"Reset successful",
            signinText:"Sign in >",

            //
            authCodeIsNull:'请输入验证码',
            passwordIsNull:'请输入密码',
            passwordIsNotEqual:'两次密码不一致',
            passwordIsInvalid:'密码格式不正确',
            passwordIsTooShort:"密码少于6位",
            passwordIsTooLong:"密码长于16位",
            // 数据
            emailKey:'',
            codeKey:'',
            countryCode :'',
            phoneArea:'',
            phone:'',
            step: 1,

            phneContent: '  +86-13800138000',
            authcode:'',
            defaultResendText:'重新发送',
            resendText:'重新发送',
            lastTime :60,
            authForm:{
                authcode:''
            },
            form: {
                newpass: '',
                confirmpass: ''
            },
            authRules: {
                authcode:[{validator:checkAuthCodeRule,trigger:'blur'}]
            },
            resetRules: {
                newpass:[{validator:checkNewPassRule,trigger:'blur'}],
                confirmpass:[{validator:checkConfirmPassRule,trigger:'blur'}]
            },
            timer :null,
        }
    },
    mounted(){
        _self = this;
        this.emailKey = this.$route.query.key;

        this.step1Title = this.$t(_module+'.step1Title');
        this.phoneLabel = this.$t(_module+'.phoneLabel');
        this.phoneNotifyContent = this.$t(_module+'.phoneNotifyContent');
        this.clickinfo = this.$t(_module+'.clickinfo');
        this.authcodePlaceholder = this.$t(_module+'.authcodePlaceholder');
        this.step1ButtonText = this.$t(_module+'.step1ButtonText');
        this.step2Title = this.$t(_module+'.step2Title');
        this.newpassLabel = this.$t(_module+'.newpassLabel');
        this.confirmpassLabel = this.$t(_module+'.confirmpassLabel');
        this.newpassPlaceholder = this.$t(_module+'.newpassPlaceholder');
        this.confirmpassPlaceholder = this.$t(_module+'.confirmpassPlaceholder');
        this.step2ButtonText = this.$t(_module+'.step2ButtonText');
        this.step2notice = this.$t(_module+'.step2notice');
        this.successinfo = this.$t(_module+'.successinfo');
        this.signinText = this.$t('CTLogin.buttonText');
        this.authCodeIsNull = this.$t(_module+'.authCodeIsNull');
        this.passwordIsNull = this.$t(_module+'.passwordIsNull');
        this.passwordIsNotEqual = this.$t(_module+'.passwordIsNotEqual');
        this.passwordIsInvalid = this.$t(_module+'.passwordIsInvalid');
        this.passwordIsTooShort = this.$t(_module+'.passwordIsTooShort');
        this.passwordIsTooLong = this.$t(_module+'.passwordIsTooLong');
        this.defaultResendText = this.$t(_module+'.defaultResendText');
        this.resendText = this.$t(_module+'.defaultResendText');
        this.verificationCode = this.$t('CTMobileverify.verificationCode');
    },
    methods: {
        // 验证码检测规则
        checkAuthCode (rule,value,callback){
            if(value==''){
                callback(new Error(this.authCodeIsNull));
            } else {
                callback();
            }
        },
        // 密码检测规则
        checkPassword (rule,value,callback){
            if(value==''){
                callback(new Error(this.passwordIsNull));
            } else if(value.length <6){
                callback(new Error(this.passwordIsTooShort));
            } else if(value.length >16){
                callback(new Error(this.passwordIsTooLong));
            } else {
                var reg= /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*(),.]+$)[a-zA-Z\d/\\!@#$%^&*(),.=+-_'|{}:;"~`]{6,16}$/;
                if(!reg.test(value)){
                    callback(new Error(this.passwordIsInvalid));
                }else {
                    callback();
                }
                //callback();
            }
        },
        // 重复密码检测规则
        checkConfirmPassword(rule,value,callback){
            if(this.form.newpass!=value){
                callback(new Error(this.passwordIsNotEqual));
            } else {
                callback();
            }
        },
        // 更新密码按钮
        doResetPass(){
            this.$refs.resetForm.validate(valid=>{
                if(valid){
                    _self.callUpdatePasswordApi();
                } else {
                    return false
                }
            })
            //this.callUpdatePasswordApi()
            //this.step =2;
        },
        // 更新密码API
        callUpdatePasswordApi(){
            var params = new Object();
            // params.pwd = this.form.newpass;
            params.key = this.emailKey;
            this.axios.post('/registerct/noremembersms',params).then(ret=>{
                if(ret.status === 1){
                    _self.step = 2;
                    _self.codeKey = ret.data.codeKey;
                    _self.countryCode = ret.data.countryCode;
                    _self.phoneArea = ret.data.mobileArea;
                    _self.phone = ret.data.mobile;
                    _self.doResetSendButton();
                } else {
                    _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
                }
                //console.log(ret);
            }).catch(err=>{
                if(_self.$showLog)
                    console.log(err);
            })
        },

        // 重发短信按钮
        doResend(){
            if(this.resendText != this.defaultResendText){
                return;
            }
            this.callResendSMS();
        },
        // 重发短信API
        callResendSMS(){
            var params = new Object();
            params.codeKey = this.codeKey;
            params.key = this.emailKey;
            params.mobile = this.phone;
            params.countryCode = this.countryCode;
            params.mobileArea = this.phoneArea;

            this.axios.post('/registerct/againsmsmessage',params).then(ret=>{
                if(ret.status === 1){
                    //_self.step = 3;
                    _self.doResetSendButton();
                } else {
                    _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
                }
                if(_self.$showLog)
                    console.log(ret);
            }).catch(err=>{
                if(_self.$showLog)
                    console.log(err);
            })
        },

        // 倒计时重置
        doResetSendButton(){
            var self = this;
            this.lastTime = 60;
            this.resendText=this.lastTime + "s";
            this.timer = setInterval(function () {
                self.lastTime --;
                if(self.lastTime <= -1){
                    self.resendText= self.defaultResendText;
                    clearInterval(self.timer);
                } else {
                    self.resendText=self.lastTime + "s";
                }
            },1000);
        },

        // 验证码提交按钮
        doSubmit(){
            this.$refs.authForm.validate(valid => {
                if (valid) {
                    //_self.step = 3;
                    _self.callSubmitAuthCode();
                } else {
                    if(_self.$showLog)
                        console.log('error submit!!')
                    return false
                }
            })
        },
        // 验证码提交API
        callSubmitAuthCode(){
            var params = new Object();
            params.codeKey = this.codeKey;
            params.smsCode = this.authForm.authcode;
            params.pwd = this.form.newpass;
            this.axios.post('/registerct/noremembersubmit',params).then(ret=>{
                if(ret.status === 1){
                    _self.step = 3;
                } else {
                    _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
                }
                if(_self.$showLog)
                    console.log(ret);
            }).catch(err=>{
                if(_self.$showLog)
                    console.log(err);
            })
        },

        loginClick(){
            this.$router.push({
                path: '/loginMobile',
                query: {}
            })
        },
    }
}