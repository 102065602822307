<template>
    <div class="resetpass-mobile">
        <!-- step 1 -->
        <div class="resetpass-verifyForm" v-if="step==1">
            <div class="formheader"  >
                {{step2Title}}
            </div>

            <el-form ref="resetForm" :model="form" :rules="resetRules" label-position="top">
                <el-form-item class="inputitem" :label="newpassLabel" prop="newpass">
                    <el-input type="password"  v-model="form.newpass" :placeholder="newpassPlaceholder" ></el-input>
                </el-form-item>
                <el-form-item class="inputitem" :label="confirmpassLabel" prop="confirmpass">
                    <el-input type="password"  v-model="form.confirmpass" :placeholder="confirmpassPlaceholder" ></el-input>
                </el-form-item>
            </el-form>

            <el-button type="primary" class="step2-button" @click.native="doResetPass">{{step2ButtonText}}</el-button>
            <div class="step2-bottom">
                <div class="bottomnotice">{{step2notice}}</div>
            </div>

        </div>
        <!-- step 2 -->
        <div class="resetpass-verifyForm" v-if="step==2">
            <div class="formheader" >
                {{step2Title}}
            </div>
            <div class="verifyline" >
                <div class="content">
                    <div class="phoneline">
                        <div class="phoneitem">{{phoneLabel}}</div>
                        <div class="phoneitem">{{phoneArea}}-{{phone}}</div>
                    </div>

                    <div class="nodity">{{phoneNotifyContent}}</div>
                    <div class="clickedinfo">{{clickinfo}}</div>
                </div>
            </div>
            <el-form ref="authForm" :model="form" label-width="140px" :rules="authRules" label-position="top">
                <el-form-item class="resetpass-authencodebox" :label="verificationCode" prop="authcode" label-position="top">
                    <div class="authenCode-view" style="background: #FFFFFF;
                border: 1px solid #CAD3DE;
                border-radius: 4px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;">
                        <el-input v-model="authForm.authcode" :placeholder="authcodePlaceholder" style="border: none; border-radius: 0px"></el-input>
                        <div class="resend" @click="doResend()">{{resendText}}</div>
                    </div>

                </el-form-item>

            </el-form>
            <el-button type="primary" class="step2-button" @click.native="doSubmit">{{step2ButtonText}}</el-button>

        </div>

        <!-- step 3 -->
        <div class="resetpass-verifyForm" v-if="step==3">
            <div class="succesbox">
                <div class="success" ></div>
            </div>
            <div class="successinfobox">
                <div class="successinfo">{{successinfo}}</div>
            </div>

            <div class="bottom">
                <el-button type="primary" class="bottom-login-button" @click.native="loginClick">{{signinText}}</el-button>
            </div>

        </div>
    </div>
</template>

<script>
    import index from './index'
    export default index
</script>

<style lang="less">
    .resetpass-mobile {
        padding: 20px;
        .resetpass-verifyForm{
            width: 100%;
            background: #FFFFFF;
        }

        .formheader{
            margin-top: 8px;
            color:#333333;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 30px;
            font-weight: bold;
        }
        .emptyline{
            height: 30px;
        }

        .inputitem{

        }


        .step1-button{
            margin-top: 30px;
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            background-color: #0073FF;
            box-shadow: 0 0 10px 0 rgba(0,115,255,0.20);
            font-size: 18px;

        }


        .step1-bottom{
            margin-top: 30px;
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;

        }

        .bottomnotice{
            color: #ACBDC7;
        }

        .verifyline{
            display: flex;
            margin-bottom: 30px;
            .content{
                color:#333333
            }
            .phoneline{
                display: flex;
            }
            .phoneitem{
                font-size: 16px;
                line-height: 22px;
            }
            .nodity {
                margin-top: 20px;
                color:#666666;
                font-size: 16px;
            }
            .clickedinfo{
                color:#cccccc;
            }

        }

        .icon{
            width: 100px;
            height: 100px;
            background:url(../../../assets/images/phone.png) no-repeat 0 0;
            background-size: 100% 100%;
        }

        .notice{
            width: 520px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #81899E;
        }

        .verifycodebox{
            margin: 0px 40px 0px 40px;
            width: 520px;
            height: 120px;
            background: #F0F7FB;
            border-radius: 6px;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            .phoneicon{
                margin:44px 10px 44px 40px;
                width: 32px;
                height: 32px;
                background:url(../../../assets/images/phone1.png) no-repeat 0 0;
                background-size: 100% 100%;
            }
            .resetpass-authencodebox{
                width: 400px;
                height: 40px;
                margin-right: 40px;
                background: #FFFFFF;
                border: 1px solid #CAD3DE;
                border-radius: 4px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

        }
        .resend{
            width: 80px;
            text-align: right;
            margin-right: 10px;
            color: #0073FF;
        }
        .step2-button{
            margin-top: 30px;
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            font-size: 18px;
            background-color: #0073FF;
            box-shadow: 0 0 10px 0 rgba(0,115,255,0.20);
        }
        .succesbox{
            margin-top: 10px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .success{
            width: 100px;
            height: 100px;
            background:url(../../../assets/images/success.png) no-repeat 0 0;
            background-size: 100% 100%;
        }

        .successinfobox{
            margin-top: 20px;
            display: flex;
            justify-content: center;


        }
        .successinfo{
            font-size: 20px;
            color: #274776;
        }

        .bottom{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .bottom-login-button {
            margin-top: 40px;
            width: 100%;
            height: 50px;
            background-color: #0073FF;
            box-shadow: 0 0 10px 0 rgba(0,115,255,0.20);
            font-size: 18px;
            color: #ffffff;
        }

        .link{
            font-size: 14px;
            color: #0073FF;
            letter-spacing: 0;
            cursor: pointer;
        }

        .step2-bottom{
            margin-top: 30px;
            width: 100%;
            display: flex;
            justify-content: center;
            /*text-align: center;*/
            align-items: center;
        }

        .resetpass-verifyForm .el-input__inner {
            height: 50px;
            line-height: 50px;
        }
        .resetpass-authencodebox .el-input__inner {
            border: 0 none;
            border-radius: 0px;
            height: 50px;
            /*width: 300px;*/
            line-height: 50px;
        }
        .resetpass-verifyForm .el-form-item__label {
            line-height: 20px;
        }
}
</style>
