<template>
    <div class="mainwindow">
        <!-- step 1 -->
        <div class="verifyForm" >
            <div class="formheader"  >
                {{step1Title}}
            </div>
            <div class="emptyline"></div>

            <el-form ref="modifyForm"
                     :model="form"
                     label-width="140px"
                     :rules="modifyRules">
                <el-row>
                    <el-col :span="24">
                        <el-form-item class="inputitem" :label="oldpassLabel"
                                      prop="oldpass">
                            <el-input type="password"  v-model="form.oldpass" :placeholder="oldpassPlaceholder" ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item class="inputitem" :label="newpassLabel"
                                      prop="newpass">
                            <el-input type="password"  v-model="form.newpass" :placeholder="newpassPlaceholder" ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item class="inputitem" :label="confirmpassLabel"
                                      prop="confirmpass">
                            <el-input type="password"  v-model="form.confirmpass" :placeholder="confirmpassPlaceholder" ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item class="inputitem" :label="$t('CTModifyPass.emailValidateCode')" prop="authcode">
                    <div class="authCodeForm">
                      <el-input  v-model="form.authcode" @keyup.native="authCodeInput" :placeholder="$t('CTMobileverify.verificationCode')"></el-input>
                      <el-button class="resend" @click.native="callSendCodeApi()">{{ resendButtonText }}</el-button>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <el-button type="primary" class="step2-button" @click.native="doResetPass">{{step2ButtonText}}</el-button>
            <div class="step2-bottom">
            </div>

        </div>


    </div>

</template>

<script>
    import utils from "../../../util/utils";
    var _self;
    const _module='CTModifyPass';
    const _common="ctError";
    const checkCurrentPassRule=(rule,value,callback)=>{
        _self.checkCurrentPass(rule,value,callback);
    }
    const checkNewPassRule=(rule,value,callback)=>{
        _self.checkPassword(rule,value,callback);
    }
    const checkConfirmPassRule=(rule,value,callback)=>{
        _self.checkConfirmPassword(rule,value,callback);
    }
    const checkAuthCodeRule=(rule,value,callback)=>{
      _self.checkAuthCode(rule,value,callback);
    }
    export default {
        name: 'login',
        components: {
        },
        data() {
            return {
                // step 1
                step1Title:"Change your password",
                oldpassLabel:"Current Password:",
                newpassLabel:"New Password:",
                oldpassPlaceholder:"Current Password",
                confirmpassLabel:"Confirm Password:",
                newpassPlaceholder:"New Password",
                confirmpassPlaceholder:"Confirm Password",
                step2ButtonText:"Update Password",


                //
                oldpasswordIsNull:'请输入旧密码',
                passwordIsNull:'请输入密码',
                passwordIsNotEqual:'两次密码不一致',
                passwordIsInvalid:'密码格式不正确',
                passwordIsTooShort:"密码少于6位",
                passwordIsTooLong:"密码长于16位",
                // 数据
                redirect:'',
                form: {
                    oldpass:'',
                    newpass: '',
                    confirmpass: '',
                    authcode:'',
                },

                modifyRules: {
                    oldpass:[{validator:checkCurrentPassRule,trigger:'blur'}],
                    newpass:[{validator:checkNewPassRule,trigger:'blur'}],
                    confirmpass:[{validator:checkConfirmPassRule,trigger:'blur'}],
                    authcode:[{validator:checkAuthCodeRule,trigger:'blur'}],
                },
                timer :null,
              resendButtonText:'',
            }
        },
        mounted(){
            _self = this;
            this.emailKey = this.$route.query.key;
            this.redirect = this.$route.query.redirect;

            this.step1Title = this.$t(_module+'.step1Title');
            this.oldpassLabel = this.$t(_module+'.oldpassLabel');
            this.newpassLabel = this.$t(_module+'.newpassLabel');
            this.oldpassPlaceholder = this.$t(_module+'.oldpassPlaceholder');
            this.confirmpassLabel = this.$t(_module+'.confirmpassLabel');
            this.newpassPlaceholder = this.$t(_module+'.newpassPlaceholder');
            this.confirmpassPlaceholder = this.$t(_module+'.confirmpassPlaceholder');
            this.step2ButtonText = this.$t(_module+'.step2ButtonText');
            this.oldpasswordIsNull = this.$t(_module+'.oldpasswordIsNull');
            this.passwordIsNull = this.$t(_module+'.passwordIsNull');
            this.passwordIsNotEqual = this.$t(_module+'.passwordIsNotEqual');
            this.passwordIsInvalid = this.$t(_module+'.passwordIsInvalid');
            this.passwordIsTooShort = this.$t(_module+'.passwordIsTooShort');
            this.passwordIsTooLong = this.$t(_module+'.passwordIsTooLong');
            this.resendButtonText = this.$t(_module+'.sendEmailCode');
        },
        methods: {
          authCodeInput(){
            this.form.authcode = utils.dealInputPassswordVal(this.form.authcode,4);
          },
            // 老密码规则
            checkCurrentPass (rule,value,callback){
                if(value==''){
                    callback(new Error(this.oldpasswordIsNull));
                } else {
                    callback();
                }
            },
            // 密码检测规则
            checkPassword (rule,value,callback){
                if(value==''){
                    callback(new Error(this.passwordIsNull));
                } else if(value.length <6){
                    callback(new Error(this.passwordIsTooShort));
                } else if(value.length >16){
                    callback(new Error(this.passwordIsTooLong));
                } else {
                    var reg= /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*(),.]+$)[a-zA-Z\d/\\!@#$%^&*(),.=+-_'|{}:;"~`]{6,16}$/;
                    if(!reg.test(value)){
                        callback(new Error(this.passwordIsInvalid));
                    }else {
                        callback();
                    }
                    //callback();
                }
            },
            // 重复密码检测规则
            checkConfirmPassword(rule,value,callback){
                if(this.form.newpass!=value){
                    callback(new Error(this.passwordIsNotEqual));
                } else {
                    callback();
                }
            },

          //验证码检测规则
          checkAuthCode(rule,value,callback){
            if(value==''){
              callback(new Error(this.$t(`CTMobileverify.authCodeIsNul`)));
            } else {
              callback();
            }
          },

          //发邮箱验证码
          callSendCodeApi(){
            var params = new Object();
            params.token = _self.$cookies.get('token');
            this.sendCodeDisabled = true
            let _this = this
            this.axios.post('/cicTrade/usercenter/pwdsendemail',params).then(ret=>{
              _this.sendCodeDisabled = false
              if(ret.status === 1){
                _this.doResetSendButton();
              } else {
                _this.$message.error(_this.$t(_common+'.'+ret.errorMsg));
              }
              if(_this.$showLog)
                console.log(ret);
            }).catch(err=>{
              _this.sendCodeDisabled = false
              if(_this.$showLog)
                console.log(err);
            })
          },

          doResetSendButton(){
              console.log('倒计时开始')
            var self = this;
            this.lastTime = 60;
            this.resendButtonText=this.lastTime + "s";
            this.timer = setInterval(function () {
              self.lastTime --;
              if(self.lastTime <= -1){
                self.resendButtonText= self.$t(_module+'.sendEmailCodeAgain');
                clearInterval(self.timer);
              } else {
                self.resendButtonText=self.lastTime + "s";
              }
            },1000)
          },
            // 更新密码按钮
            doResetPass(){
                this.$refs.modifyForm.validate(valid=>{
                    if(valid){
                        _self.callModifyPasswordApi();
                    } else {
                        return false
                    }
                })
                //this.callUpdatePasswordApi()
                //this.step =2;
            },
            // 更新密码API
            callModifyPasswordApi(){
                var params = new Object();
                // params.pwd = this.form.newpass;
                params.newPwd = this.form.newpass;
                params.pwd = this.form.oldpass;
                params.token = _self.$cookies.get('token');
                params.code = this.form.authcode
                this.axios.post('/cicTrade/usercenter/resetpwd',params).then(ret=>{
                    if(ret.status === 1){
                        window.location.href = _self.redirect;
                    } else {
                        _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
                    }
                    if(_self.$showLog)
                    console.log(ret);
                }).catch(err=>{
                    if(_self.$showLog)
                    console.log(err);
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "./index.less";
</style>

<style lang="less">
    .mainwindow {
        .el-main{
            height: 100%;
        }
        .verifyForm .el-input__inner {
            height: 40px;
            line-height: 40px;
        }
        .verifyForm .el-input .el-input__clear{
            margin-top:5px;
        }
        .verifyForm .countrySelect{
            width: 380px;
        }
        .verifyForm .el-form-item__label{
            text-align: left;
            color: #666666;
            line-height: 40px;
        }
        .verifyForm .inputitem-right .el-form-item__content{
            margin-left: 10px !important;
        }

        .verifyForm .el-checkbox__label{
            color: #666666;
        }
        .verifyForm .el-checkbox__input.is-checked+.el-checkbox__label{
            color: #666666;
        }
        .verifyForm .el-form-item{
            margin-bottom: 30px;
        }
        .verifycodebox .el-form-item{
            margin-bottom: 0px;
        }
        .verifycodebox .el-form-item__content{
            margin-left: 0px !important;
            display: flex;
        }
        .authCodeForm .el-input{
            //width: 300px;
        }

        .authCodeForm .el-input__inner {
            border: 0 none;
            border-radius: 0px;
            height: 30px;
            //width: 300px;
            line-height: 30px;
        }


    }



</style>
