<template>
    <div class="mainwindow">
        <!-- step 1 -->
        <div class="verifyForm" v-if="step==1">
            <div class="formheader"  >
                {{step2Title}}
            </div>
            <div class="emptyline"></div>

            <el-form ref="resetForm"
                     :model="form"
                     label-width="140px"
                     :rules="resetRules">
                <el-row>
                    <el-col :span="24">
                        <el-form-item class="inputitem" :label="newpassLabel"
                                      prop="newpass">
                            <el-input type="password"  v-model="form.newpass" :placeholder="newpassPlaceholder" ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item class="inputitem" :label="confirmpassLabel"
                                      prop="confirmpass">
                            <el-input type="password"  v-model="form.confirmpass" :placeholder="confirmpassPlaceholder" ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-button type="primary" class="step2-button" @click.native="doResetPass">{{step2ButtonText}}</el-button>
            <div class="step2-bottom">
                <div class="bottomnotice">{{step2notice}}</div>
            </div>

        </div>
        <!-- step 2 -->
        <div class="verifyForm" v-if="step==2">
            <div class="formheader" >
                {{step1Title}}
            </div>
            <div class="emptyline"></div>
            <div class="verifyline">
                <div class="icon"></div>
                <div class="content">
                    <div class="phoneline">
                        <div class="phoneitem">{{phoneLabel}}</div>
                        <div class="phoneitem" style="margin-left: 12px">{{phoneArea}}-{{phone}}</div>
                    </div>
                    <div class="nodity">{{phoneNotifyContent}}</div>
                    <div class="clickedinfo">{{clickinfo}}</div>
                </div>
            </div>
            <div class="emptyline"></div>
            <el-form ref="authForm"
                     :model="authForm"
                     label-width="140px"
                     :rules="authRules">
                <div class="verifycodebox" >
                    <div class="phoneicon"></div>

                    <el-form-item class="authencodebox"
                                  prop="authcode">
                        <el-input  v-model="authForm.authcode" :placeholder="authcodePlaceholder"></el-input>
                        <div class="resend" @click="doResend()">{{resendText}}</div>
                    </el-form-item>

                </div>
            </el-form>
            <!--
            <div class="verifycodebox">
                <div class="phoneicon" ></div>
                <div class="authencodebox">
                    <el-input  v-model="authcode" :placeholder="authcodePlaceholder"></el-input>
                    <div class="resend" @click="doResend()">{{resendText}}</div>
                </div>
            </div>
            -->
            <el-button type="primary" class="step1-button" @click.native="doSubmit">{{step1ButtonText}}</el-button>

        </div>

        <!-- step 3 -->
        <div class="verifyForm" v-if="step==3">
            <div class="formheader" >
            </div>
            <div class="succesbox">
                <div class="success" ></div>
            </div>
            <div class="successinfobox">
                <div class="successinfo">{{successinfo}}</div>
            </div>

            <div class="bottom">
                <router-link to="/login" replace class="link">{{signinText}}</router-link>
            </div>

        </div>
    </div>

</template>

<script>
    import utils from "../../../util/utils";

    var _self;
    const _module='CTResetPass';
    const _common="ctError";
    const checkAuthCodeRule=(rule,value,callback)=>{
        _self.checkAuthCode(rule,value,callback);
    }
    const checkNewPassRule=(rule,value,callback)=>{
        _self.checkPassword(rule,value,callback);
    }
    const checkConfirmPassRule=(rule,value,callback)=>{
        _self.checkConfirmPassword(rule,value,callback);
    }
    export default {
        name: 'login',
        components: {
        },
        data() {
            return {
                // step 1
                step1Title: "Enter authentication code",
                phoneLabel: "Mobile:",
                phoneNotifyContent: 'A comfirm message has been send to your phone',
                clickinfo:"Please enter the verify code",
                authcodePlaceholder:"Please enter the verify code",
                step1ButtonText:"Submit",
                // step 2
                step2Title:"Reset your password",
                newpassLabel:"New Password:",
                confirmpassLabel:"Confirm Password:",
                newpassPlaceholder:"New Password",
                confirmpassPlaceholder:"Confirm Password",
                step2ButtonText:"Update Password",
                step2notice:"Security is critical at CIC. To keep your account safe, we will text you a verification code to confirm the password reset.",
                // step 3
                successinfo:"Reset successful",
                signinText:"Sign in >",

                //
                authCodeIsNull:'请输入验证码',
                passwordIsNull:'请输入密码',
                passwordIsNotEqual:'两次密码不一致',
                passwordIsInvalid:'密码格式不正确',
                passwordIsTooShort:"密码少于6位",
                passwordIsTooLong:"密码长于16位",
                // 数据
                emailKey:'',
                codeKey:'',
                countryCode :'',
                phoneArea:'',
                phone:'',
                step: 1,

                phneContent: '  +86-13800138000',
                authcode:'',
                defaultResendText:'重新发送',
                resendText:'重新发送',
                lastTime :60,
                authForm:{
                    authcode:''
                },
                form: {
                    newpass: '',
                    confirmpass: ''
                },
                authRules: {
                    authcode:[{validator:checkAuthCodeRule,trigger:'blur'}]
                },
                resetRules: {
                    newpass:[{validator:checkNewPassRule,trigger:'blur'}],
                    confirmpass:[{validator:checkConfirmPassRule,trigger:'blur'}]
                },
                timer :null,
            }
        },
        mounted(){
            if (!utils.isPC()){
                this.$router.push({
                    path: '/resetpassMobile',
                    query: this.$route.query
                })
                return
            }
            _self = this;
            this.emailKey = this.$route.query.key;


            this.step1Title = this.$t(_module+'.step1Title');
            this.phoneLabel = this.$t(_module+'.phoneLabel');
            this.phoneNotifyContent = this.$t(_module+'.phoneNotifyContent');
            this.clickinfo = this.$t(_module+'.clickinfo');
            this.authcodePlaceholder = this.$t(_module+'.authcodePlaceholder');
            this.step1ButtonText = this.$t(_module+'.step1ButtonText');
            this.step2Title = this.$t(_module+'.step2Title');
            this.newpassLabel = this.$t(_module+'.newpassLabel');
            this.confirmpassLabel = this.$t(_module+'.confirmpassLabel');
            this.newpassPlaceholder = this.$t(_module+'.newpassPlaceholder');
            this.confirmpassPlaceholder = this.$t(_module+'.confirmpassPlaceholder');
            this.step2ButtonText = this.$t(_module+'.step2ButtonText');
            this.step2notice = this.$t(_module+'.step2notice');
            this.successinfo = this.$t(_module+'.successinfo');
            this.signinText = this.$t(_module+'.signinText');
            this.authCodeIsNull = this.$t(_module+'.authCodeIsNull');
            this.passwordIsNull = this.$t(_module+'.passwordIsNull');
            this.passwordIsNotEqual = this.$t(_module+'.passwordIsNotEqual');
            this.passwordIsInvalid = this.$t(_module+'.passwordIsInvalid');
            this.passwordIsTooShort = this.$t(_module+'.passwordIsTooShort');
            this.passwordIsTooLong = this.$t(_module+'.passwordIsTooLong');
            this.defaultResendText = this.$t(_module+'.defaultResendText');
            this.resendText = this.$t(_module+'.defaultResendText');
        },
        methods: {
            // 验证码检测规则
            checkAuthCode (rule,value,callback){
                if(value==''){
                    callback(new Error(this.authCodeIsNull));
                } else {
                    callback();
                }
            },
            // 密码检测规则
            checkPassword (rule,value,callback){
                if(value==''){
                    callback(new Error(this.passwordIsNull));
                } else if(value.length <6){
                    callback(new Error(this.passwordIsTooShort));
                } else if(value.length >16){
                    callback(new Error(this.passwordIsTooLong));
                } else {
                    var reg= /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*(),.]+$)[a-zA-Z\d/\\!@#$%^&*(),.=+-_'|{}:;"~`]{6,16}$/;
                    if(!reg.test(value)){
                        callback(new Error(this.passwordIsInvalid));
                    }else {
                        callback();
                    }
                    //callback();
                }
            },
            // 重复密码检测规则
            checkConfirmPassword(rule,value,callback){
                if(this.form.newpass!=value){
                    callback(new Error(this.passwordIsNotEqual));
                } else {
                    callback();
                }
            },
            // 更新密码按钮
            doResetPass(){
                this.$refs.resetForm.validate(valid=>{
                    if(valid){
                        _self.callUpdatePasswordApi();
                    } else {
                        return false
                    }
                })
                //this.callUpdatePasswordApi()
                //this.step =2;
            },
            // 更新密码API
            callUpdatePasswordApi(){
                var params = new Object();
                // params.pwd = this.form.newpass;
                params.key = this.emailKey;
                this.axios.post('/registerct/noremembersms',params).then(ret=>{
                    if(ret.status === 1){
                        _self.step = 2;
                        _self.codeKey = ret.data.codeKey;
                        _self.countryCode = ret.data.countryCode;
                        _self.phoneArea = ret.data.mobileArea;
                        _self.phone = ret.data.mobile;
                        _self.doResetSendButton();
                    } else {
                        _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
                    }
                    //console.log(ret);
                }).catch(err=>{
                    if(_self.$showLog)
                        console.log(err);
                })
            },

            // 重发短信按钮
            doResend(){
                if(this.resendText != this.defaultResendText){
                    return;
                }
                this.callResendSMS();
            },
            // 重发短信API
            callResendSMS(){
                var params = new Object();
                params.codeKey = this.codeKey;
                params.key = this.emailKey;
                params.mobile = this.phone;
                params.countryCode = this.countryCode;
                params.mobileArea = this.phoneArea;

                this.axios.post('/registerct/againsmsmessage',params).then(ret=>{
                    if(ret.status === 1){
                        //_self.step = 3;
                        _self.doResetSendButton();
                    } else {
                        _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
                    }
                    if(_self.$showLog)
                        console.log(ret);
                }).catch(err=>{
                    if(_self.$showLog)
                        console.log(err);
                })
            },

            // 倒计时重置
            doResetSendButton(){
                var self = this;
                this.lastTime = 60;
                this.resendText=this.lastTime + "s";
                this.timer = setInterval(function () {
                    self.lastTime --;
                    if(self.lastTime <= -1){
                        self.resendText= self.defaultResendText;
                        clearInterval(self.timer);
                    } else {
                        self.resendText=self.lastTime + "s";
                    }
                },1000);
            },

            // 验证码提交按钮
            doSubmit(){
                this.$refs.authForm.validate(valid => {
                    if (valid) {
                        //_self.step = 3;
                        _self.callSubmitAuthCode();
                    } else {
                        if(_self.$showLog)
                            console.log('error submit!!')
                        return false
                    }
                })
            },
            // 验证码提交API
            callSubmitAuthCode(){
                var params = new Object();
                params.codeKey = this.codeKey;
                params.smsCode = this.authForm.authcode;
                params.pwd = this.form.newpass;
                this.axios.post('/registerct/noremembersubmit',params).then(ret=>{
                    if(ret.status === 1){
                        _self.step = 3;
                    } else {
                        _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
                    }
                    if(_self.$showLog)
                        console.log(ret);
                }).catch(err=>{
                    if(_self.$showLog)
                        console.log(err);
                })
            },
        }
    }
</script>

<style lang="less" scoped>
    @import "./index.less";
</style>

<style lang="less">
    .mainwindow {
        .el-main{
            height: 100%;
        }
        .verifyForm .el-input__inner {
            height: 40px;
            line-height: 40px;
        }
        .verifyForm .el-input .el-input__clear{
            margin-top:5px;
        }
        .verifyForm .countrySelect{
            width: 380px;
        }
        .verifyForm .el-form-item__label{
            text-align: left;
            color: #666666;
            line-height: 40px;
        }
        .verifyForm .inputitem-right .el-form-item__content{
            margin-left: 10px !important;
        }

        .verifyForm .el-checkbox__label{
            color: #666666;
        }
        .verifyForm .el-checkbox__input.is-checked+.el-checkbox__label{
            color: #666666;
        }
        .verifyForm .el-form-item{
            margin-bottom: 30px;
        }
        .verifycodebox .el-form-item{
            margin-bottom: 0px;
        }
        .verifycodebox .el-form-item__content{
            margin-left: 0px !important;
            display: flex;
        }
        .authencodebox .el-input{
            width: 300px;
        }

        .authencodebox .el-input__inner {
            border: 0 none;
            border-radius: 0px;
            height: 30px;
            width: 300px;
            line-height: 30px;
        }
    }



</style>
