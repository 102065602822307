<template>
  <div class="dataPro-view">
    <div class="background-view">
    </div>
    <div class="content-view">
      <li class="dataPro-title"><span>{{ $t('ctLogin.backShortly') }}</span></li>
      <li class="dataPro-content"><span>{{ $t('ctLogin.backLater') }}</span></li>
      <li class="dataPro-bottom"><span>{{ $t('ctLogin.thsUnderstand') }}</span></li>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.dataPro-view {
  font-size: 14px;
  color: #333333;

  .background-view {
    width: 100%;
    height: 100%; /**宽高100%是为了图片铺满屏幕 */
    position: fixed;
    background: url("../../assets/images/dataProBackImage.png") center center no-repeat;
    background-size: 100% 100%;
  }

  .content-view {
    z-index: 1;
    position: absolute;
  }

  .dataPro-title {
    font-weight: bold;
    font-size: 60px;
    color: #ffffff;
    margin-left: 18%;
    margin-top: 150px;
  }

  .dataPro-content {
    font-size: 36px;
    color: #ffffff;
    margin-left: 18%;
    margin-right: 42%;
    margin-top: 50px;
  }

  .dataPro-bottom {
    margin-left: 18%;
    margin-right: 42%;
    margin-top: 50px;
    font-size: 36px;
    color: #ffffff;
  }
}
</style>